import { InfiniteGrid } from './infinite_grid';

function interval(x, y, step = 1) {
    let result = [];
    if(x < y) {
        for (var i = x; i <= y; i += step) {
            result.push(i);
        }
    } else {
        for (var i = x; i >= y; i -= step) {
            result.push(i);
        }
    }
    return result;
};

function arePointsIdentical(A, B) {
    return A.x == B.x && A.y == B.y;
}

function getTrackPoints(track) {
    let points = [];
    var path = track.data.path;
    for(var i = 0; i < path.length - 1; i++) {
        var segmentPoints = [];
        var segmentAngle = computeVectorAngle(path[i], path[i + 1], true);
        if(path[i].x == path[i + 1].x) segmentPoints = interval(path[i].y, path[i + 1].y, 2).map(y => { return { x: path[i].x, y, segmentAngle } });
        else if(path[i].y == path[i + 1].y) segmentPoints = interval(path[i].x, path[i + 1].x, 2).map(x => { return { x, y: path[i].y, segmentAngle } });
        else {
            var xPoints = interval(path[i].x, path[i + 1].x);
            var yPoints = interval(path[i].y, path[i + 1].y);
            segmentPoints = xPoints.map((x, i) => { return { x, y: yPoints[i], segmentAngle } });
        }
        segmentPoints.pop();
        points = points.concat(segmentPoints);
    }
    points.push(path[path.length - 1]);
    return points;
}

function getClosestTrackPoint(point, tracks, { maximumDistance = -1 }, includeEdges = false) {
    return tracks.reduce((tPrevious, tCurrent) => {
        let trackPoints = getTrackPoints(tCurrent);
        if(!includeEdges) {
            trackPoints.shift(); trackPoints.pop();
        }
        if(!trackPoints.length) return tPrevious;
        let closestPointFromCurrentTrack = trackPoints.reduce((pPrevious, pCurrent) => {
            return computeDistanceBetweenPoints(pCurrent, point) < computeDistanceBetweenPoints(pPrevious, point)
                ? pCurrent : pPrevious;
        }, trackPoints[0]);
        closestPointFromCurrentTrack.track = tCurrent

        if(computeDistanceBetweenPoints(closestPointFromCurrentTrack, point) < tPrevious.distance)
            return {
                distance: computeDistanceBetweenPoints(closestPointFromCurrentTrack, point),
                point: closestPointFromCurrentTrack,
            };
        else return tPrevious;
    }, { distance : maximumDistance >= 0 ? maximumDistance : Number.MAX_SAFE_INTEGER });
}

function isPointOnSegment(C, AB, epsilon = 0.01) {
    var crossproduct = (C.y - AB[0].y) * (AB[1].x - AB[0].x) - (C.x - AB[0].x) * (AB[1].y - AB[0].y);
    if(Math.abs(crossproduct) > epsilon) return false;

    var dotproduct = (C.x - AB[0].x) * (AB[1].x - AB[0].x) + (C.y - AB[0].y)*(AB[1].y - AB[0].y)
    if(dotproduct < 0) return false;

    var squaredlengthba = (AB[1].x - AB[0].x)*(AB[1].x - AB[0].x) + (AB[1].y - AB[0].y)*(AB[1].y - AB[0].y)
    if(dotproduct > squaredlengthba) return false;

    return true;
}

function getSegmentsIntersection(AB, CD) {
    let s1 = { x: AB[1].x - AB[0].x, y: AB[1].y - AB[0].y };
    let s2 = { x: CD[1].x - CD[0].x, y: CD[1].y - CD[0].y };
    let hpt = s1.x * s2.y - s2.x * s1.y;
    let s = (- s1.y * (AB[0].x - CD[0].x) + s1.x * (AB[0].y - CD[0].y)) / hpt
    let t = (s2.x * (AB[0].y - CD[0].y) - s2.y * (AB[0].x - CD[0].x)) / hpt
    if(s >= 0 && s <= 1 && t >= 0 && t <= 1) {
        return { x: AB[0].x + (t * s1.x), y: AB[0].y + (t * s1.y) }
    }
}

function getPathIntersections(tracks, path) {
    let intersections = [];
    tracks.forEach(track => {
        for(var i = 0; i < track.data.path.length - 1; i++) {
            for(var j = 0; j < path.length - 1; j++) {
                var intersection = getSegmentsIntersection([track.data.path[i], track.data.path[i + 1]], [path[j], path[j + 1]]);
                // (On vérifie que l'intersection se situe bien sur un point)
                if(intersection != null) {
                    intersections.push({ track, point: intersection });
                }
            }
        }
    })
    return intersections;
}

function getClosestTracksIntersection(point, tracks, { maximumDistance = Number.MAX_SAFE_INTEGER }) {
    let closestTrackIntersection = { distance: maximumDistance };
    tracks.forEach(trackA => {
        tracks.forEach(trackB => {
            if(trackA == trackB) return;
            else {
                var pathA = trackA.data.path;
                var pathB = trackB.data.path;
                for(var i = 0; i < pathA.length - 1; i++) {
                    for(var j = 0; j < pathB.length - 1; j++) {
                        var intersection = getSegmentsIntersection([pathA[i], pathA[i + 1]], [pathB[j], pathB[j + 1]]);
                        // (On vérifie que l'intersection se situe bien sur un point)
                        if(intersection != null && (intersection.x - point.x) % 1 == 0 && (intersection.y - point.y) % 1 == 0) {
                            if(computeDistanceBetweenPoints(intersection, point) < closestTrackIntersection.distance) {
                                closestTrackIntersection = intersection;
                                closestTrackIntersection.tracks = [trackA, trackB];
                                closestTrackIntersection.distance = computeDistanceBetweenPoints(closestTrackIntersection, point);
                            } else if(arePointsIdentical(closestTrackIntersection, intersection)) {
                                if(closestTrackIntersection.tracks.indexOf(trackA) < 0) closestTrackIntersection.tracks.push(trackA);
                                if(closestTrackIntersection.tracks.indexOf(trackB) < 0) closestTrackIntersection.tracks.push(trackB);
                            }
                        }
                    }
                }
            }
        })
    })
    return closestTrackIntersection.tracks != null ? closestTrackIntersection : null;
}

function getTrackSubPath(track, pointA, pointB) {
    let subPath = [];
    let secondPointToEncounter = null;
    var path = track.data.path;
    for(var i = 0; i < path.length - 1; i++) {
        if(subPath.length == 0) {
            var intersectionsFound = { A: isPointOnSegment(pointA, [path[i], path[i + 1]]), B: isPointOnSegment(pointB, [path[i], path[i + 1]]) };
            if(intersectionsFound.A && intersectionsFound.B) return [pointA, pointB];
            if(intersectionsFound.A) {
                secondPointToEncounter = pointB;
                subPath.push(pointA, path[i + 1]);
            } else if(intersectionsFound.B) {
                secondPointToEncounter = pointA;
                subPath.push(pointB, path[i + 1]);
            } else continue;
        } else {
            var intersectionsFound = isPointOnSegment(secondPointToEncounter, [path[i], path[i + 1]]);
            if(!intersectionsFound) subPath.push(path[i + 1]);
            else {
                subPath.push(secondPointToEncounter);
                return subPath;
            }
        }
    }
    return subPath;
}

function getPathLength(path) {
    var length = 0;
    if(path.length < 2) return 0;
    for(var i = 0; i < path.length - 1; i++) {
        length += computeDistanceBetweenPoints(path[i], path[i + 1])
    }
    return length;
}

function computeVectorAngle(A, B, alwaysUp = false) {
    if(A.x == B.x) return A.y < B.y && !alwaysUp ? 90 : -90;
    else if(A.y == B.y) return A.x < B.x && !alwaysUp ? 180 : 0;
    if(A.x < B.x) {
        return -InfiniteGrid.computeAngleABC(A, A.y < B.y ? { x: A.x, y: B.y } : { x: B.x, y: A.y }, B);
    } else {
        return InfiniteGrid.computeAngleABC(A, A.y < B.y ? { x: B.x, y: A.y } : { x: A.x, y: B.y } , B) * (A.y > B.y ? 1 : -1)
    }
}

function computeDistanceBetweenPoints(A, B) {
    return Math.sqrt(Math.pow(A.x - B.x, 2) + Math.pow(A.y - B.y, 2));
}

export {
    interval,
    arePointsIdentical,
    getTrackPoints,
    getClosestTrackPoint,
    getClosestTracksIntersection,
    isPointOnSegment,
    getSegmentsIntersection,
    getPathIntersections,
    getTrackSubPath,
    computeVectorAngle,
    computeDistanceBetweenPoints,
    getPathLength
}