import { Controller } from '@hotwired/stimulus';
import { SynoptiqueGridHandler } from '../js/synoptique_grid_handler';
import { saveAll, parseSynoptique, drawSynoptique, saveSynoptique } from '../js/synoptique-elements-handler';
import { EDITOR_OBJECTS } from '../js/synoptique-elements-config';
import 'formBuilder/dist/form-render.min.js';

function dragElement(elem, onUpdate, onClose) {
    var offset = { x: 0, y: 0 }, prevPos = { x: 0, y: 0 };
    elem.onmousedown = dragMouseDown;
  
    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      prevPos.x = e.clientX;
      prevPos.y = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }
  
    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        offset.x = prevPos.x - e.clientX;
        offset.y = prevPos.y - e.clientY;
        prevPos.x = e.clientX;
        prevPos.y = e.clientY;
        // set the element's new position:
        var newPosition = {
            x: parseFloat($(elem).css('left').replace('px', '')) - offset.x,
            y: parseFloat($(elem).css('top').replace('px', '')) - offset.y
        };
        $(elem).css('left', newPosition.x + "px");
        $(elem).css('top', newPosition.y + "px");
        if(onUpdate) onUpdate(newPosition);
    }
  
    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;

      if(onClose) onClose();
    }
}

export default class extends Controller {
    editor;
    plumber;
    gridHandler;
    elementVisualiser;
    visualizedElement;
    visualizedElementType;
    synoptique;
    pastChanges = [];
    futureChanges = [];
    itemsToDelete = {
        tracks: [],
        edges: [],
        derailers: [],
        instrumentalized_sections: [],
        construction_sections: [],
        switches: [],
        tips: [],
        notes: [],
    };

    static values = {
        synoptique: Object
    }

    connect() {
        this.editor = $(this.element).find('.editor');
        this.elementVisualiser = $(this.element).find('.visualisation-element');
        this.elementVisualiser.find('#close').on('click', () => {
            $(this.element).find('.visualized').removeClass('visualized');
            this.visualizedElement = null;
            this.elementVisualiser.hide()
        });
        this.initEditorMenu();
        this.synoptique = parseSynoptique(this.synoptiqueValue);
        this.gridHandler = new SynoptiqueGridHandler(this.synoptique, this.editor, (tag, obj) => this.attachEvents(tag, obj));
        this.drawSynoptique();

        $('#synoptiqueConfirmBtn').on('click', (e) => {
            e.preventDefault();
            let isNew = !this.synoptique.id;
            $('body').css('cursor', 'wait');
            saveAll(this.synoptique, this.itemsToDelete).then(() => {
                if(isNew && this.synoptique.id) window.history.replaceState('page', 'Portail Synoptique', '/synoptique/' + this.synoptique.id + '/edit');
            }).finally(() => $('body').css('cursor', 'auto'));
        })

        $('.subtitle[contenteditable]').on('input', (e) => {
            this.synoptique.title = e.target.innerText;
        });

        $('.visualisation-element .sub-title .btn-danger').on('click', () => {
            if(confirm('Voulez-vous vraiment supprimer cet élément ?')) {
                switch(this.visualizedElementType) {
                    case 'track':
                        if(this.visualizedElement.id > 0) this.itemsToDelete.tracks.push(this.visualizedElement.id);
                        this.synoptique.tracks.splice(this.synoptique.tracks.indexOf(this.visualizedElement), 1);
                        this.synoptique.tips.forEach((tip) => {
                            if(
                                this.visualizedElement == tip.data.track
                                || this.visualizedElement.id == tip.data.trackId
                            ) {
                                tip.data.track = null;
                                tip.data.trackId = null;
                                tip.data.trackNumber = null;
                            }
                            if(
                                this.visualizedElement.startingEdge == tip.data.edge || this.visualizedElement.startingEdge?.id == tip.data.edgeId
                                ||this.visualizedElement.closingEdge == tip.data.edge || this.visualizedElement.closingEdge?.id == tip.data.edgeId
                            ) {
                                tip.data.edge = null;
                                tip.data.edgeId = null;
                                tip.data.edgeLabel = null;
                            }
                        })
                        break;
                    case 'track-derailer':
                        if(this.visualizedElement.id > 0) this.itemsToDelete.derailers.push(this.visualizedElement.id);
                        for (var iTrack = 0; iTrack < this.synoptique.tracks.length; iTrack++) {
                            var elementIndex = this.synoptique.tracks[iTrack].derailers.indexOf(this.visualizedElement);
                            if(elementIndex >= 0) {
                                this.synoptique.tracks[iTrack].derailers.splice(elementIndex, 1);
                                break;
                            }
                        }
                        break;
                    case 'track-edge':
                        if(this.visualizedElement.id > 0) this.itemsToDelete.edges.push(this.visualizedElement.id);
                        for (var iTrack = 0; iTrack < this.synoptique.tracks.length; iTrack++) {
                            if(this.visualizedElement == this.synoptique.tracks[iTrack].startingEdge) {
                                this.synoptique.tracks[iTrack].startingEdge = null;
                                break;
                            } else if(this.visualizedElement == this.synoptique.tracks[iTrack].closingEdge) {
                                this.synoptique.tracks[iTrack].closingEdge = null;
                            }
                        }
                        this.synoptique.tips.forEach((tip) => {
                            if(
                                this.visualizedElement == tip.data.edge
                                || this.visualizedElement.id == tip.data.edgeId
                            ) {
                                tip.data.edge = null;
                                tip.data.edgeId = null;
                                tip.data.edgeLabel = null;
                            }
                        })
                        break;
                    case 'track-section':
                        if(this.visualizedElement.id > 0) this.itemsToDelete.instrumentalized_sections.push(this.visualizedElement.id);
                        for (var iTrack = 0; iTrack < this.synoptique.tracks.length; iTrack++) {
                            var elementIndex = this.synoptique.tracks[iTrack].instrumentalizedSections.indexOf(this.visualizedElement);
                            if(elementIndex >= 0) {
                                this.synoptique.tracks[iTrack].instrumentalizedSections.splice(elementIndex, 1);
                                break;
                            }
                        }
                        break;
                    case 'track-construction-section':
                        if(this.visualizedElement.id > 0) this.itemsToDelete.construction_sections.push(this.visualizedElement.id);
                        for (var iTrack = 0; iTrack < this.synoptique.tracks.length; iTrack++) {
                            var elementIndex = this.synoptique.tracks[iTrack].constructionSections.indexOf(this.visualizedElement);
                            if(elementIndex >= 0) {
                                this.synoptique.tracks[iTrack].constructionSections.splice(elementIndex, 1);
                                break;
                            }
                        }
                        break;
                    case 'track-switch':
                        if(this.visualizedElement.id > 0) this.itemsToDelete.switches.push(this.visualizedElement.id);
                        this.synoptique.switches.splice(this.synoptique.switches.indexOf(this.visualizedElement), 1);
                        break;
                    case 'note':
                        if(this.visualizedElement.id > 0) this.itemsToDelete.notes.push(this.visualizedElement.id);
                        this.synoptique.notes.splice(this.synoptique.notes.indexOf(this.visualizedElement), 1);
                        break;
                    case 'tip':
                        if(this.visualizedElement.id > 0) this.itemsToDelete.tips.push(this.visualizedElement.id);
                        this.synoptique.tips.splice(this.synoptique.tips.indexOf(this.visualizedElement), 1);
                        break;
                }
                this.drawSynoptique();
                this.elementVisualiser.hide();
            }
        })

        $('.client-picker select').on('change', (e) => {
            this.synoptique.clientId = $(e.target).val();
            $('body').css('cursor', 'wait');
            saveSynoptique(this.synoptique)
                .then(() => $('body').css('cursor', 'auto'))
        })

        $('.set-synoptique-access').on('click', (e) => {
            this.synoptique.public = $(e.target).hasClass('public');
            $('body').css('cursor', 'wait');
            saveSynoptique(this.synoptique)
                .then(() => { $(e.target).toggleClass('public private'); $('body').css('cursor', 'auto'); })
        })
    }

    initEditorMenu() {
        $(this.element).find('.expander').on('click', () => $(this.element).find('.synoptique-menu').addClass('expanded'));
        $(this.element).find('.reducer').on('click', () => $(this.element).find('.synoptique-menu').removeClass('expanded'));
        $(this.element).find('.synoptique-menu .menu-entry').on('click', (e) => {
            e.stopPropagation();
            let newAction = $(e.target).closest('.menu-entry').data('action');
            this.gridHandler.initAction(newAction)
        });
        $(this.element).find('#elementForm').formRender();
        $(this.element).find('[for="elementForm"]').on('click', (e) => {
            var formData = new FormData($(this.element).find('#elementForm').get(0));
            if(this.visualizedElement.data.dualSensor) this.visualizedElement.data.dualSensor = false;
            if(this.visualizedElement.data.sensorBEnabled) this.visualizedElement.data.sensorBEnabled = false;
            if(this.visualizedElement.data.routeFromStartEnabled) this.visualizedElement.data.routeFromStartEnabled = false;
            if(this.visualizedElement.data.routeFromEndEnabled) this.visualizedElement.data.routeFromEndEnabled = false;
            for (var formEntry of formData.entries()) {
                this.visualizedElement.data[formEntry[0].replace('[]', '')] = formEntry[1];
            }
            if(this.visualizedElement.data.trackId) this.visualizedElement.track = this.synoptique.tracks.reduce((prev, cur) => cur.id == this.visualizedElement.data.trackId ? cur : prev, null);
            if(this.visualizedElement.data.edgeId) this.visualizedElement.edge = this.synoptique.tracks.reduce((prevT, curT) => [curT.startingEdge, curT.closingEdge].filter((e) => e).reduce((prevE, curE) => curE.id == this.visualizedElement.data.edgeId ? curE : prevE, null) ?? prevT, null);
            this.visualizedElement = null;
            this.elementVisualiser.hide();
            this.drawSynoptique();
        });
    }

    drawSynoptique() {
        drawSynoptique(this.synoptique, this.editor, this.gridHandler.grid, (tag, obj) => this.attachEvents(tag, obj));
    }

    drawElementForm(objConfig, obj) {
        let dataObj = obj.data;
        this.elementVisualiser.show();
        this.elementVisualiser.find('.element-type').text(objConfig.label);
        $(this.element).find('#elementForm').formRender('setData', Object.values(objConfig.detailForm ?? {}).map((formData) => {
            if(formData.getValues) formData.values = formData.getValues(this.synoptique, dataObj);
            formData.value = dataObj[formData.name];
            formData.disabled = false;
            return JSON.parse(JSON.stringify(formData));
        }));
        $(this.element).find('.element-action-bar').empty();
        objConfig.detailFormActions?.forEach((formAction) => {
            var btn = $(`<button type="button" class="btn ${formAction.class}">${formAction.label}</button>`);
            btn.on('click', () => {
                formAction.action.apply(this, [obj, $($('.visualized').first())]);
                if(formAction.redrawAfter) {
                    this.drawSynoptique();
                    this.drawElementForm(objConfig, obj);
                }
            })
            $(this.element).find('.element-action-bar').append(btn);
        });
        $(this.element).find('#elementForm').formRender('render');
    }

    attachEvents(tag, obj) {
        let dataObj = obj.data;
        if(obj.visualized) tag.addClass('visualized');
        Object.values(EDITOR_OBJECTS).forEach((objConfig) => {
            tag.find(Array.isArray(objConfig.classTagToClick) ? ('.' + objConfig.classTagToClick.join(', .')) :  '.' + objConfig.classTagToClick)
              .addBack('.' + objConfig.classTagToClick).on('dblclick', () => {
                $(this.element).find('.visualized').removeClass('visualized');
                tag.addClass('visualized');
                if(this.visualizedElement) this.visualizedElement.visualized = false;
                obj.visualized = true;
                this.visualizedElement = obj;
                this.visualizedElementType = objConfig.mainClassTag;
                this.drawElementForm(objConfig, obj);
            });
        });
        let trackNumber = tag.find('.track-number');
        if(trackNumber.length > 0) {
            dragElement(trackNumber.get(0), (newPosition) => {
                dataObj.xNumberPosition = Math.round(newPosition.x)
                dataObj.yNumberPosition = Math.round(newPosition.y)
            });
        }
        let trackEdgeLabel = tag.find('.track-edge-label');
        if(trackEdgeLabel.length > 0) {
            dragElement(trackEdgeLabel.get(0), (newPosition) => {
                dataObj.labelPosition.x = Math.round(newPosition.x)
                dataObj.labelPosition.y = Math.round(newPosition.y)
            });
        }
        let noteContent = tag.find('.note-content');
        if(noteContent.length > 0) {
            noteContent.on('paste input', (e) => dataObj.content = $(e.target).text());
        }

        return tag;
    }
}