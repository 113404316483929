import { arePointsIdentical, computeVectorAngle, getClosestTrackPoint, getTrackSubPath, computeDistanceBetweenPoints } from '../js/grid-2d-engine';
import { deleteItems } from './synoptique-elements-handler';

const EDITOR_OBJECTS = {
    track: {
        label: 'Voie',
        mainClassTag: 'track',
        classTagToClick: ['track-number', 'track-line'],
        detailInfos: {
            number: {
                type: 'text',
                label: 'Numéro',
            },
        },
        detailForm: {
            number: {
                type: 'text',
                subtype: 'text',
                label: 'Numéro',
                className: 'form-control',
                name: 'number'
            },
            routeFromStartEnabled: {
                type: 'checkbox-group',
                values: [
                    {
                        value: true,
                        label: 'Afficher les routes partant du point A',
                        selected: false
                    }
                ],
                className: 'checkbox',
                name: 'routeFromStartEnabled'
            },
            routeFromEndEnabled: {
                type: 'checkbox-group',
                values: [
                    {
                        value: true,
                        label: 'Afficher les routes partant du point B',
                        selected: false
                    }
                ],
                className: 'checkbox',
                name: 'routeFromEndEnabled'
            },
        },
        getTag(track, grid) {
            var mainTag = $(`<div class="${this.mainClassTag}" data-id="${track.id}"></div>`);
            mainTag.append($(`<div class="${this.mainClassTag}-number" style="left: ${track.data.xNumberPosition}px; top: ${track.data.yNumberPosition}px;"><span>${track.data.number ?? ''}</span></div>`));

            var trackStart = $(`<div class="${this.mainClassTag}-start"></div>`);
            grid.placeElement(trackStart, track.data.path[0]);
            mainTag.append(trackStart);

            var trackStepLine = $(`<svg class="${this.mainClassTag}-line"><polyline id="inner-white"/></svg>`);
            mainTag.append(trackStepLine);
            for (var i = 1; i < track.data.path.length; i++) {
                var trackStep = $(`<div class="${this.mainClassTag}-${i == track.data.path.length - 1 ? 'end' : 'step'}"></div>`);
                grid.placeElement(trackStep, track.data.path[i]);
                mainTag.append(trackStep);
            }
            grid.connectElements(trackStepLine, track.data.path, { elementId: 'inner-white' });

            return mainTag;
        }
    },
    switch: {
        label: 'Aiguille',
        mainClassTag: 'track-switch',
        classTagToClick: 'track-switch-branch-tablet',
        detailInfos: {
            number: {
                type: 'text',
                label: 'Numéro',
            },
            sensorAId: {
                type: 'text',
                label: 'ID du capteur A',
            },
            sensorAValue: {
                type: 'checkbox',
                label: 'Plaqué (A)'
            }, 
            lastMeasureDateSensorA: {
                type: 'datetime',
                label: 'Date de la dernière mesure (A)'
            },
            sensorBId: {
                type: 'text',
                label: 'ID du capteur B',
            },
            sensorBValue: {
                type: 'checkbox',
                label: 'Plaqué (B)'
            }, 
            lastMeasureDateSensorB: {
                type: 'datetime',
                label: 'Date de la dernière mesure (B)'
            },
        },
        detailForm: {
            number: {
                type: 'text',
                label: 'Numéro',
                className: 'form-control',
                name: 'number'
            },
            sensorAId: {
                type: 'text',
                label: 'ID du capteur A',
                className: 'form-control',
                name: 'sensorAId'
            },
            sensorBEnabled: {
                type: 'checkbox-group',
                values: [
                    {
                        value: true,
                        label: 'Activer le capteur B',
                        selected: false
                    }
                ],
                className: 'checkbox',
                name: 'sensorBEnabled'
            },
            sensorBId: {
                type: 'text',
                label: 'ID du capteur B',
                className: 'form-control',
                name: 'sensorBId'
            },
        },
        detailFormActions: [
            {
                label: 'Inverser A et B',
                class: 'btn-primary',
                action(obj) {
                    var aPosition = obj.data.branchingTracks[0].position, aTrack = obj.data.branchingTracks[0].track, aTrackId = obj.data.branchingTracks[0].trackId;
                    obj.data.branchingTracks[0].position = obj.data.branchingTracks[1].position;
                    obj.data.branchingTracks[0].track = obj.data.branchingTracks[1].track;
                    obj.data.branchingTracks[0].trackId = obj.data.branchingTracks[1].trackId;
                    obj.data.branchingTracks[1].position = aPosition;
                    obj.data.branchingTracks[1].track = aTrack;
                    obj.data.branchingTracks[1].trackId = aTrackId;
                },
                redrawAfter: true
            }
        ],
        getTag(trackSwitch, grid) {
            var mainTag = $(`<div
                class="${this.mainClassTag}"
                data-id="${trackSwitch.id}"
                data-sensor-a-id="${trackSwitch.data.sensorAId}"
                data-sensor-b-id="${trackSwitch.data.sensorBEnabled ? trackSwitch.data.sensorBId : ''}"
            "></div>`);
            var switchIntersection = $(`<div class="${this.mainClassTag}-intersection"><div class="switch-branch-number">${trackSwitch.data.number ?? ''}</div></div>`);
            grid.placeElement(switchIntersection, trackSwitch.data.intersection);
            mainTag.append(switchIntersection);
            trackSwitch.data.branchingTracks.forEach((bt, i) => {
                var switchBranch = $(`<div class="${this.mainClassTag}-branch-tablet" data-id="${bt.id}"></div>`);
                grid.placeElement(switchBranch, bt.position);
                mainTag.append(switchBranch);
            });

            return mainTag;
        }
    },
    edge: {
        label: 'Extremité de voie',
        mainClassTag: 'track-edge',
        classTagToClick: 'track-edge',
        detailInfos: {
            label: {
                type: 'text',
                label: 'Label'
            }
        },
        detailForm: {
            label: {
                type: 'text',
                subtype: 'text',
                label: 'Label',
                className: 'form-control',
                name: 'label'
            }
        },
        getTag(edge, grid, track) {
            var mainTag = $(`<div class="${this.mainClassTag}" data-id="${edge.id}">
                <div class="${this.mainClassTag}-label" style="left: ${edge.data.labelPosition.x}px; top: ${edge.data.labelPosition.y}px;">${edge.data.label ?? ''}</div>
            </div>`);
            var angle = 0;
            if(edge === track.startingEdge) {
                angle = computeVectorAngle(track.data.path[0], track.data.path[1]);
                grid.placeElement(mainTag, track.data.path[0])
            }
            if(edge === track.closingEdge) {
                angle = computeVectorAngle(track.data.path[track.data.path.length - 1], track.data.path[track.data.path.length - 2]);
                grid.placeElement(mainTag, track.data.path[track.data.path.length - 1]);
            }

            mainTag.addClass('rotate' + Math.round(angle % 180));
            // mainTag.find(`.${this.mainClassTag}-label`).css('rotate', (360 - angle) + 'deg');

            return mainTag;
        }
    },
    derailer: {
        label: 'Taquet Dérailleur',
        mainClassTag: 'track-derailer',
        classTagToClick: 'track-derailer',
        detailInfos: {
            sensorId: {
                type: 'text',
                label: 'ID du capteur'
            },
            currentState: {
                type: 'select',
                choices: {
                    0: 'Relevé',
                    1: 'Baissé'
                },
                label: 'Etat actuel du capteur',
                placeholder: 'Inconnu'
            },
            lastMeasureDate: {
                type: 'datetime',
                label: 'Date de la dernière mesure'
            },
        },
        detailForm: {
            sensorId: {
                type: 'text',
                label: 'ID du capteur',
                className: 'form-control',
                name: 'sensorId'
            }
        },
        detailFormActions: [
            {
                label: 'Repositionner',
                class: 'btn-primary',
                action(obj, tag) {
                    if(this.nodeToPlace) this.gridHandler.exitPlacementMode();
                    else {
                        obj.tags = [tag];
                        obj.oldTrack = obj.track ?? this.synoptique.tracks.filter(t => t.id == obj.data.trackId)[0];
                        this.gridHandler.initRepositionAction(ACTIONS.add_derailer, obj);
                    }
                }
            }
        ],
        getTag(derailer, grid, track) {
            var mainTag = $(`<div class="${this.mainClassTag}" data-id="${derailer.id}" data-sensor-id="${derailer.data.sensorId}">
                <div class="derailer-sensor-id">${derailer.data.sensorId?.split('-')[0].replace(/\D/g,'') ?? ''}</div>
            </div>`);
            var angle = getClosestTrackPoint(derailer.data.position, [track], { maximumDistance: 0.1 }).point?.segmentAngle ?? 0;
            grid.placeElement(mainTag, derailer.data.position);
            mainTag.css('rotate', angle + 'deg');

            return mainTag;
        }
    },
    note: {
        label: 'Texte',
        mainClassTag: 'note',
        classTagToClick: 'note',
        detailInfos: {
            content: {
                type: 'text',
                label: 'Contenu'
            },
            fontSize: {
                type: 'number',
                label: 'Taille de caractère'
            }
        },
        detailForm: {
            content: {
                type: 'text',
                label: 'Contenu',
                className: 'form-control',
                name: 'content'
            },
            backgroundColor: {
                type: 'text',
                subtype: 'color',
                label: 'Couleur de fond',
                className: 'form-control',
                name: 'backgroundColor'
            },
            backgroundOpacity: {
                type: 'number',
                subtype: 'number',
                min: 0,
                max: 1,
                label: 'Opacité du fond',
                className: 'form-control',
                name: 'backgroundOpacity'
            },
            fontColor: {
                type: 'text',
                subtype: 'color',
                label: 'Couleur du texte',
                className: 'form-control',
                name: 'fontColor'
            },
            fontSize: {
                type: 'number',
                subtype: 'number',
                label: 'Taille de caractère',
                className: 'form-control',
                name: 'fontSize'
            }
        },
        detailFormActions: [
            {
                label: 'Repositionner',
                class: 'btn-primary',
                action(obj, tag) {
                    if(this.nodeToPlace) this.gridHandler.exitPlacementMode();
                    else {
                        obj.tags = [tag];
                        this.gridHandler.initRepositionAction(ACTIONS.add_text, obj);
                    }
                }
            }
        ],
        getTag(note, grid) {
            var mainTag = $(`<div class="${this.mainClassTag}" data-id="${note.id}" style="--note-background-color: ${note.data.backgroundColor + Math.round((note.data.backgroundOpacity ?? 1) * 255).toString(16)};--note-font-color: ${note.data.fontColor};--note-font-size: ${note.data.fontSize}px;">
                <div class="note-content">${note.data.content}</div>
            </div>`);
            grid.placeElement(mainTag, note.data.position);

            return mainTag;
        }
    },
    tip: {
        label: 'TIP',
        mainClassTag: 'tip',
        classTagToClick: 'tip',
        detailInfos: {
            label: {
                type: 'text',
                label: 'Titre'
            },
            trackNumber: {
                type: 'text',
                label: 'Voie'
            },
            edgeLabel: {
                type: 'numbetextr',
                label: 'Fin de voie'
            }
        },
        detailForm: {
            label: {
                type: 'text',
                label: 'Titre',
                className: 'form-control',
                name: 'label'
            },
            track: {
                type: 'select',
                required: true,
                label: 'Voie',
                className: 'form-control',
                name: 'trackId',
                multiple: false,
                getValues: (synoptique, tipData) => synoptique.tracks.filter((track) => track?.data.number).map((track) => { return { 
                    label: track.data.number,
                    value: track.id,
                    selected: track.id == tipData.trackId
                }})
            },
            edge: {
                type: 'select',
                required: true,
                label: 'Fin de voie',
                className: 'form-control',
                name: 'edgeId',
                multiple: false,
                getValues: (synoptique, tipData) => [].concat(...synoptique.tracks.map((track) => [track.startingEdge, track.closingEdge].filter((edge) => edge?.data.label).map(
                    (edge) => { return {
                        label: edge.data.label,
                        value: edge.id,
                        selected: edge.id == tipData.edgeId
                    }})
                ))
            }
        },
        detailFormActions: [
            {
                label: 'Repositionner',
                class: 'btn-primary',
                action(obj, tag) {
                    if(this.nodeToPlace) this.gridHandler.exitPlacementMode();
                    else {
                        obj.tags = [tag];
                        this.gridHandler.initRepositionAction(ACTIONS.add_tip, obj);
                    }
                }
            }
        ],
        getTag(tip, grid) {
            var mainTag = $(`<div class="${this.mainClassTag}" data-id="${tip.id}">
                    <div class="${this.mainClassTag}-label">${tip.data.label ?? 'TIP'}</div>
                    <div class="${this.mainClassTag}-routing"><span class="${this.mainClassTag}-routing-left">${tip.track?.data.number ?? tip.data.trackNumber ?? '?'}</span> <> <span class="${this.mainClassTag}-routing-left">${tip.edge?.data.label ?? tip.data.edgeLabel ?? '?'}</span></div>
                </div>`);
            grid.placeElement(mainTag, tip.data.position);

            return mainTag;
        }
    },
    section: {
        label: 'Section instrumentalisée de voie',
        mainClassTag: 'track-section',
        classTagToClick: 'track-section-line',
        detailInfos: {
            sensorStartId: {
                type: 'text',
                label: 'ID du capteur A'
            },
            axleCountAtStart: {
                type: 'number',
                label: 'Valeur du compteur d\'essieux (A)'
            },
            lastMeasureSensorStartDate: {
                type: 'datetime',
                label: 'Date de la dernière mesure (A)',
            },
            sensorEndId: {
                type: 'text',
                label: 'ID du capteur B'
            },
            axleCountAtEnd: {
                type: 'number',
                label: 'Valeur du compteur d\'essieux (B)'
            },
            lastMeasureSensorEndDate: {
                type: 'datetime',
                label: 'Date de la dernière mesure (B)',
            },
        },
        detailForm: {
            sensorStartId: {
                type: 'text',
                label: 'ID du capteur A',
                className: 'form-control',
                name: 'sensorStartId'
            },
            dualSensor: {
                type: 'checkbox-group',
                values: [
                    {
                        value: true,
                        label: 'Activer le capteur B',
                        selected: false
                    }
                ],
                className: 'checkbox',
                name: 'dualSensor'
            },
            sensorEndId: {
                type: 'text',
                label: 'ID du capteur B',
                className: 'form-control',
                name: 'sensorEndId'
            }
        },
        detailFormActions: [
            {
                label: 'Inverser A et B',
                class: 'btn-primary',
                action(obj) {
                    var aPosition = obj.data.positionStart;
                    obj.data.positionStart = obj.data.positionEnd;
                    obj.data.positionEnd = aPosition;
                },
                redrawAfter: true
            }
        ],
        getTag(section, grid, track) {
            var mainTag = $(`<div class="${this.mainClassTag}" data-id="${section.id}" data-sensor-start-id="${section.data.sensorStartId}" data-sensor-end-id="${section.data.sensorEndId}"></div>`);
            var subTags = [
                $(`<div class="${this.mainClassTag}-start">
                    <div class="section-sensor-id">${section.data.sensorStartId?.split('-')[0].replace(/\D/g,'') ?? ''}</div>
                </div>`),
                $(`<svg class="${this.mainClassTag}-line"><polyline/></svg>`),
                $(`<div class="${this.mainClassTag}-counter"><span class="${this.mainClassTag}-counter-value">0</span>`),
                $(`<div class="${this.mainClassTag}-end">
                    <div class="section-sensor-id">${(section.data.dualSensor ? section.data.sensorEndId?.split('-')[0].replace(/\D/g,'') : '') ?? ''}</div>   
                </div>`)
            ];
            grid.placeElement(subTags[0], section.data.positionStart);
            var angles = [ getClosestTrackPoint(section.data.positionStart, [track], { maximumDistance: 0.1 }).point.segmentAngle, getClosestTrackPoint(section.data.positionEnd, [track], { maximumDistance: 0.1 }).point.segmentAngle ];
            subTags[0].css('rotate', angles[0] + 'deg');
            subTags[3].css('rotate', angles[1] + 'deg');
            let subPath = getTrackSubPath(track, section.data.positionStart, section.data.positionEnd);
            let longestSegment = subPath.reduce((currentSegment, point, i) =>  {
                if(!currentSegment)
                    return [point, subPath[i + 1]];
                else if(i === subPath.length - 1)
                    return currentSegment;
                else if(computeDistanceBetweenPoints(point, subPath[i + 1]) > computeDistanceBetweenPoints(currentSegment[0], currentSegment[1]))
                    return [point, subPath[i + 1]];
                else
                    return currentSegment;
            }, null);

            grid.connectElements(subTags[1], subPath);
            grid.placeElement(subTags[2], { x: (longestSegment[0].x + longestSegment[1].x) / 2, y: (longestSegment[0].y + longestSegment[1].y) / 2 });
            grid.placeElement(subTags[3], section.data.positionEnd);
            mainTag.append(subTags);

            return mainTag;
        }
    },
    construction_section: {
        label: 'Section de voie en travaux',
        mainClassTag: 'track-construction-section',
        classTagToClick: ['track-construction-section-start', 'track-construction-section-end', 'track-construction-section-hatches'],
        detailViewActions: [
            {
                label: 'Supprimer',
                class: 'btn-danger',
                action(obj) {
                    if(confirm('Voulez-vous vraiment supprimer cet élément ?')) {
                        if(obj.id > 0) this.itemsToDelete.construction_sections.push(obj.id);
                        for (var iTrack = 0; iTrack < this.synoptique.tracks.length; iTrack++) {
                            var elementIndex = this.synoptique.tracks[iTrack].constructionSections.indexOf(obj);
                            if(elementIndex >= 0) {
                                this.synoptique.tracks[iTrack].constructionSections.splice(elementIndex, 1);
                                break;
                            }
                        }
                        deleteItems(this.itemsToDelete);
                    }
                },
                redrawAfter: true
            }
        ],
        getTag(section, grid, track) {
            var mainTag = $(`<div class="${this.mainClassTag}" data-id="${section.id}"></div>`);
            var subTags = [$(`<div class="track-construction-section-start"></div>`), $(`<svg class="${this.mainClassTag}-line"><polyline id="outer"/><polyline id="outer-covering"/></svg>`), $(`<div class="track-construction-section-end"></div>`)];
            grid.placeElement(subTags[0], section.data.positionStart);
            grid.placeElement(subTags[2], section.data.positionEnd);

            let subPath = getTrackSubPath(track, section.data.positionStart, section.data.positionEnd);
            mainTag.append(subTags);
            grid.connectElements(subTags[1], subPath, { elementId: 'outer' });

            for (var i = 1; i < subPath.length; i++) {
                var angle = Math.round(45 - computeVectorAngle(subPath[i], subPath[i - 1], true));
                mainTag.append(`<svg class="${this.mainClassTag}-hatches"><polyline id="dashed${i}" class="line-hatches" stroke="url(#diagonalHatch${angle})"/></svg>`);
                grid.connectElements(mainTag.find('svg').last(), [subPath[i - 1], subPath[i]], { elementId: 'dashed' + i });
            }
            grid.connectElements(subTags[1], subPath, { elementId: 'outer-covering', placeSVG: false });

            return mainTag;
        }
    }
}

const ACTIONS = {
    add_track: {
        name: 'add_track',
        helpMessages: {
            start: 'Veuillez placer le point de départ de la voie',
            continue: 'Tracer la voie segment par segment. Pour placer le point final, cliquez une 2nd fois sur le point précédent',
        },
        onStart() {
            this.nodeToPlace = {
                id: -Math.round(Math.random() * 10000000),
                data: {
                    number: null,
                    xNumberPosition: 0,
                    yNumberPosition: 0,
                    path:[{ x: 0, y: 0 }]
                },
                startingEdge: null,
                closingEdge: null,
                instrumentalizedSections: [],
                constructionSections: [],
                derailers: [],
                mainTag: $(`<div class="track"></div>`),
                tags: [$('<div class="track-start"></div>')]
            }
            this.container.append(this.nodeToPlace.mainTag);
            this.nodeToPlace.mainTag.append(...this.nodeToPlace.tags);
        },
        onMouseMove(anchorPoint) {
            this.nodeToPlace.data.path[this.nodeToPlace.data.path.length - 1] = anchorPoint;
            if(this.nodeToPlace.data.path.length > 1) {
                this.nodeToPlace.mainTag.remove();
                this.nodeToPlace.mainTag = EDITOR_OBJECTS.track.getTag(this.nodeToPlace, this.grid);
                this.nodeToPlace.tags = [this.nodeToPlace.mainTag.find('.track-start')];
                this.container.append(this.nodeToPlace.mainTag);
            }
        },
        onContinue() {
            let previousPathStep = this.nodeToPlace.data.path[this.nodeToPlace.data.path.length - 1];
            if(this.nodeToPlace.data.path.length > 1) {
                let priorPreviousPathStep = this.nodeToPlace.data.path[this.nodeToPlace.data.path.length - 2];
                if(arePointsIdentical(previousPathStep, priorPreviousPathStep)) return this.nodeToPlace.data.path.length === 2;
            }

            let newPathStep = { x: previousPathStep.x, y: previousPathStep.y };
            this.nodeToPlace.data.path.push(newPathStep);

            return true;
        },
        onConfirm() {
            // Clear last point (and the associated line) that wasn't added somewhere else to draw a line
            this.nodeToPlace.data.path.pop();
            
            // Placement du numéro de voie
            var firstPathStep = this.nodeToPlace.data.path[0];
            var lastPathStep = this.nodeToPlace.data.path[this.nodeToPlace.data.path.length - 1];
            this.nodeToPlace.data.xNumberPosition = Math.round((firstPathStep.x + lastPathStep.x) * this.grid._pointsSpacing / 2);
            this.nodeToPlace.data.yNumberPosition = Math.round((firstPathStep.y + lastPathStep.y) * this.grid._pointsSpacing / 2);
            this.nodeToPlace.mainTag = EDITOR_OBJECTS.track.getTag(this.nodeToPlace, this.grid);
            this.container.append(this.nodeToPlace.mainTag);
            this.synoptique.tracks.push(this.nodeToPlace);
            this.attachEvents(this.nodeToPlace.mainTag, this.nodeToPlace);
        },
        onExit() {

        }
    },
    add_switch: {
        name: 'add_switch',
        helpMessages: {
            start: 'Veuillez sélectionner une intersection entre plusieurs voies',
            continue: 'Veuillez placer les aiguilles sur les voies de l\'intersection. Recliquez sur une aiguille pour terminer l\'action.',
        },
        onStart() {
            id: -Math.round(Math.random() * 10000000),
            this.nodeToPlace = {
                data: {
                    number: '0'
                },
                mainTag: $(`<div class="track-switch"></div>`),
                tags: [$(`<div class="track-switch-intersection"></div>`)]
            }
            this.container.append(this.nodeToPlace.mainTag);
            this.nodeToPlace.mainTag.append(this.nodeToPlace.tags[0]);
        },
        onMouseMove(anchorPoint) {
            if(this.nodeToPlace.data.branchingTracks === undefined || this.nodeToPlace.data.branchingTracks === null) {
                this.nodeToPlace.data.intersection = anchorPoint;
                this.nodeToPlace.data.availableTracks = anchorPoint.tracks;
            } else {
                this.nodeToPlace.data.currentTrack = { track: anchorPoint.track, position: { x: anchorPoint.x, y: anchorPoint.y } };
            }
        },
        onContinue() {
            if(this.nodeToPlace.data.branchingTracks === undefined || this.nodeToPlace.data.branchingTracks === null) {
                this.nodeToPlace.data.branchingTracks = [];
            } else if(this.nodeToPlace.data.currentTrack == null) {
                return this.nodeToPlace.data.branchingTracks.length >= 2 ? false : true;
            } else {
                this.nodeToPlace.data.availableTracks.splice(this.nodeToPlace.data.availableTracks.indexOf(this.nodeToPlace.data.currentTrack.track), 1);
                this.nodeToPlace.data.branchingTracks.push(this.nodeToPlace.data.currentTrack);
                if(this.nodeToPlace.data.availableTracks.length == 0 || this.nodeToPlace.data.branchingTracks.length == 2) return false;
            }
            let newBranchTablet = $(`<div class="track-switch-branch-tablet"></div>`);
            this.nodeToPlace.tags.push(newBranchTablet);
            this.nodeToPlace.mainTag.append(newBranchTablet);
            return true;
        },
        onConfirm() {
            this.attachEvents(this.nodeToPlace.mainTag, this.nodeToPlace);
            this.synoptique.switches.push(this.nodeToPlace);
        },
        onExit() {}
    },
    instrumentalize_track: {
        name: 'instrumentalize_track',
        helpMessages: {
            start: 'Veuillez sélectionner le point de départ du tronçon de voie à instrumentaliser',
            continue: 'Veuillez sélectionner sur la même voie, le point d\'arrivée du tronçon à instrumentaliser',
        },
        onStart() {
            this.nodeToPlace = {
                id: -Math.round(Math.random() * 10000000),
                data: {
                    positionStart: { x: 0, y: 0 }
                },
                mainTag: $(`<div class="track-section"></div>`),
                tags: [$(`<div class="track-section-start"></div>`)]
            }
            this.container.append(this.nodeToPlace.mainTag);
            this.nodeToPlace.mainTag.append(...this.nodeToPlace.tags);
        },
        onMouseMove(anchorPoint) {
            if(this.nodeToPlace.data.positionEnd == null) {
                this.nodeToPlace.data.positionStart = { ...anchorPoint };
                this.nodeToPlace.track = anchorPoint.track
                this.nodeToPlace.tags[0].css('rotate', anchorPoint.segmentAngle + 'deg');
            } else {
                this.nodeToPlace.data.positionEnd = { ...anchorPoint };
                this.nodeToPlace.tags[2].css('rotate', anchorPoint.segmentAngle + 'deg');
                let subPath = getTrackSubPath(this.nodeToPlace.track, this.nodeToPlace.data.positionStart, this.nodeToPlace.data.positionEnd);
                this.grid.connectElements(this.nodeToPlace.tags[1], subPath);
            }
        },
        onContinue() {
            if(this.nodeToPlace.data.positionEnd != null) return false;

            let sectionLine = $(`<svg class="track-section-line"><polyline/></svg>`);
            let sectionEnd = $(`<div class="track-section-end"></div>`);
            this.nodeToPlace.data.positionEnd = { ...this.nodeToPlace.data.positionStart };
            this.nodeToPlace.tags.push(sectionLine, sectionEnd);
            this.nodeToPlace.mainTag.append(sectionLine, sectionEnd);
            this.grid.placeElement(sectionEnd, this.nodeToPlace.data.positionEnd);
            return true;
        },
        onConfirm() {
            this.attachEvents(this.nodeToPlace.mainTag, this.nodeToPlace);
            this.nodeToPlace.track.instrumentalizedSections.push(this.nodeToPlace);
        },
        onExit() {
        }
    },
    set_track_under_construction: {
        name: 'set_track_under_construction',
        helpMessages: {
            start: 'Veuillez sélectionner le point de départ du tronçon de voie concerné par les travaux',
            continue: 'Veuillez sélectionner sur la même voie, le point d\'arrivée du tronçon concerné par les travaux',
        },
        onStart() {
            this.nodeToPlace = {
                id: -Math.round(Math.random() * 10000000),
                data: {
                    positionStart: { x: 0, y: 0 }
                },
                mainTag: $(`<div class="track-construction-section"></div>`),
                tags: [$(`<svg class="track-construction-section-line"><polyline id="outer"/><polyline id="outer-covering"/></svg>`)]
            };
            this.container.append(this.nodeToPlace.mainTag);
            this.nodeToPlace.mainTag.append(...this.nodeToPlace.tags);
        },
        onMouseMove(anchorPoint) {
            if(this.nodeToPlace.data.positionEnd == null) {
                this.nodeToPlace.data.positionStart = { ...anchorPoint };
                this.nodeToPlace.track = anchorPoint.track;
                this.grid.connectElements(this.nodeToPlace.tags[0], [anchorPoint, anchorPoint], { elementId: 'outer' });
                this.grid.connectElements(this.nodeToPlace.tags[0], [anchorPoint, anchorPoint], { elementId: 'outer-covering', placeSVG: false });
            } else {
                this.nodeToPlace.data.positionEnd = { ...anchorPoint };
                this.nodeToPlace.mainTag.remove();
                this.nodeToPlace.mainTag = EDITOR_OBJECTS.construction_section.getTag(this.nodeToPlace, this.grid, this.nodeToPlace.track);
                this.nodeToPlace.tags = [this.nodeToPlace.mainTag.find('.track-construction-section-line')];
                this.container.append(this.nodeToPlace.mainTag);
            }
        },
        onContinue() {
            if(this.nodeToPlace.data.positionEnd != null) return false;
            this.nodeToPlace.data.positionEnd = { ...this.nodeToPlace.data.positionStart }
            return true;
        },
        onConfirm() {
            this.attachEvents(this.nodeToPlace.mainTag, this.nodeToPlace);
            this.nodeToPlace.track.constructionSections.push(this.nodeToPlace);
        },
        onExit() {
        }
    },
    add_text: {
        name: 'add_text',
        helpMessages:  {
            start: 'Veuillez placer la note',
        },
        onStart() {
            this.nodeToPlace = {
                id: -Math.round(Math.random() * 10000000),
                data: {
                    content: 'Note',
                    color: '#FFFFFF'
                },
                tags: [$(`<div class="note"><div class="note-content">Note</div></div>`)]
            }
            this.container.append(this.nodeToPlace.tags[0]);
        },
        onMouseMove(anchorPoint) {
            this.nodeToPlace.data.position = anchorPoint;
        },
        onContinue() {},
        onConfirm() {
            this.attachEvents(this.nodeToPlace.tags[0], this.nodeToPlace);
            this.synoptique.notes.push(this.nodeToPlace);
        },
        onExit() {}
    },
    add_tip: {
        name: 'add_tip',
        helpMessages:  {
            start: 'Veuillez placer l\'afficheur TIP',
        },
        onStart() {
            this.nodeToPlace = {
                id: -Math.round(Math.random() * 10000000),
                data: {
                    content: 'TIP',
                },
                tags: [$(`<div class="tip">
                    <div class="tip-label">TIP</div>
                    <div class="tip-routing"><span class="tip-routing-left">?</span> <> <span class="tip-routing-left">?</span></div>
                </div>`)]
            }
            this.container.append(this.nodeToPlace.tags[0]);
        },
        onMouseMove(anchorPoint) {
            this.nodeToPlace.data.position = anchorPoint;
        },
        onContinue() {},
        onConfirm() {
            this.attachEvents(this.nodeToPlace.tags[0], this.nodeToPlace);
            this.synoptique.tips.push(this.nodeToPlace);
        },
        onExit() {}
    },
    add_edge: {
        name: 'add_edge',
        helpMessages: {
            start: 'Veuillez sélectionner l\'extrémité d\'une voie',
        },
        onStart() {
            this.nodeToPlace = {
                id: -Math.round(Math.random() * 10000000),
                data: {
                    labelPosition: { x: 0, y: 0 }
                },
                tags: [$(`<div class="track-edge"><div class="track-edge-label"></div></div>`)],
            }
            this.nodeToPlace.mainTag = this.nodeToPlace.tags[0];
            this.container.append(this.nodeToPlace.mainTag);
        },
        onMouseMove(anchorPoint) {
            this.nodeToPlace.point = anchorPoint;
            this.nodeToPlace.track = anchorPoint.track;
            this.grid.placeElement(this.nodeToPlace.mainTag, anchorPoint)
            $(this.nodeToPlace.mainTag).removeClass('rotate-135 rotate-90 rotate-45 rotate0 rotate45 rotate90 rotate135');
            this.nodeToPlace.mainTag.addClass('rotate' + Math.round(anchorPoint.segmentAngle % 180));
        },
        onContinue() {
            return false;
        },
        onConfirm() {
            var firstPoint = this.nodeToPlace.track.data.path[0];
            var edgePoint = this.nodeToPlace.point;
            if(arePointsIdentical(firstPoint, edgePoint))
                this.nodeToPlace.track.startingEdge = this.nodeToPlace;
            else
                this.nodeToPlace.track.closingEdge = this.nodeToPlace;
            this.nodeToPlace.mainTag.remove();
            this.nodeToPlace.mainTag = EDITOR_OBJECTS.edge.getTag(this.nodeToPlace, this.grid, this.nodeToPlace.track);
            this.container.append(this.nodeToPlace.mainTag);
            this.attachEvents(this.nodeToPlace.mainTag, this.nodeToPlace);
        },
        onExit() {}
    },
    add_derailer: {
        name: 'add_derailer',
        helpMessages: {
            start: 'Veuillez placer sur une voie le taquet dérailleur',
        },
        onStart() {
            this.nodeToPlace = {
                id: -Math.round(Math.random() * 10000000),
                data: {},
                tags: [$(`<div class="track-derailer"></div>`)]
            }
            this.container.append(this.nodeToPlace.tags[0]);
        },
        onMouseMove(anchorPoint) {
            this.nodeToPlace.data.position = { ...anchorPoint };
            this.nodeToPlace.track = anchorPoint.track;
            this.nodeToPlace.tags[0].css('rotate', anchorPoint.segmentAngle + 'deg');
        },
        onContinue() {
            return false;
        },
        onModifying() {
            this.nodeToPlace.oldTrack.derailers.splice(this.nodeToPlace.oldTrack.derailers.map(d => d.id).indexOf(this.nodeToPlace.id), 1);
            this.nodeToPlace.track.derailers.push(this.nodeToPlace);
        },
        onConfirm() {
            this.nodeToPlace.track.derailers.push(this.nodeToPlace);
            this.attachEvents(this.nodeToPlace.tags[0], this.nodeToPlace);
        },
        onExit() {}
    }
};

export { EDITOR_OBJECTS, ACTIONS };