import { InfiniteGrid } from './infinite_grid';
import { arePointsIdentical, computeDistanceBetweenPoints, computeVectorAngle, getClosestTrackPoint, getClosestTracksIntersection } from './grid-2d-engine';
import { ACTIONS } from './synoptique-elements-config';

export class SynoptiqueGridHandler {
    grid;
    nodeToPlace;
    onGoingAction;
    synoptique;
    attachEvents;

    constructor(synoptique, container, attachEvents, onAddElement) {
        this.synoptique = synoptique;
        this.container = container;
        this.attachEvents = attachEvents;
        this.onAddElement = onAddElement;
        $(`<canvas class="field"></canvas>`).insertBefore(this.container)
        this.grid = new InfiniteGrid(this.container.prev('.field').get(0), {
            step: 20,
            lineWidth: 1.5,
            transformationMatrix: [
                [1, 1, 0],
                [-1, 1, 0],
                [0, 0, 1],
            ]
        });
        this.grid.init(false);
        $(this.grid.canvas).on('gridMove gridMoveEnd', (e, offset) => {
            this.container.css('translate', offset.x + 'px ' + offset.y + 'px');
        });
        $(this.grid.canvas).on('mouseMove', (e, anchorPoint) => {
            if(this.nodeToPlace != null) {
                let tag = this.nodeToPlace.tags[this.nodeToPlace.tags.length - 1];
                let acceptablePoint = this.getNodeAcceptablePlacement(this.onGoingAction, this.nodeToPlace, anchorPoint, this.synoptique)
                if(acceptablePoint != null) {
                    tag.show();
                    this.grid.placeElement(tag, { x: acceptablePoint.x, y: acceptablePoint.y });
                    this.onGoingAction.onMouseMove.apply(this, [acceptablePoint]);
                } else {
                    tag.hide();
                }
            }
        });
        $(this.grid.canvas).on('click', (e) => {
            if(this.nodeToPlace && this.getNodeAcceptablePlacement(this.onGoingAction, this.nodeToPlace, this.grid.getMouseAnchoringPoint(), this.synoptique) != null) {
                if(!this.onGoingAction.onContinue.apply(this)) {
                    this.onGoingAction.onConfirm.apply(this);
                    if(this.onAddElement) this.onAddElement(this.nodeToPlace);
                    this.exitPlacementMode();
                } else {
                    $(this.element).find('.action-help-message').text(this.onGoingAction.helpMessages.continue);
                } 
            }
        });
    }

    initAction(newAction) {
        if(this.nodeToPlace) {
            if(this.nodeToPlace.mainTag) this.nodeToPlace.mainTag.remove();
            else if(this.nodeToPlace.tags) this.nodeToPlace.tags.forEach((t) => t.remove());
            else this.nodeToPlace.tag.remove();
            if(newAction == this.onGoingAction.name) {
                this.exitPlacementMode();
                return;
            }
        }
        this.grid.show();
        this.container.addClass('item-placing');
        this.container.addClass('action-' + newAction);
        this.onGoingAction = ACTIONS[newAction];
        this.onGoingAction.onStart.apply(this);
        $(this.element).find('.action-help-message').show();
        $(this.element).find('.action-help-message').text(this.onGoingAction.helpMessages.start);
    }

    initRepositionAction(action, node) {
        this.grid.show();
        this.container.addClass('item-replacing');
        this.container.addClass('action-' + action);
        this.onGoingAction = action;
        this.nodeToPlace = node;
        $(this.element).find('.action-help-message').show();
        $(this.element).find('.action-help-message').text(this.onGoingAction.helpMessages.start);
    }

    exitPlacementMode() {
        this.onGoingAction.onExit.apply(this);
        this.container.removeClass('action-' + this.onGoingAction.name);
        this.nodeToPlace = null;
        this.onGoingAction = null;
        this.container.removeClass('item-placing');
        this.container.removeClass('item-replacing');
        $(this.element).find('.action-help-message').text();
        $(this.element).find('.action-help-message').hide();
        this.grid.hide();
    }

    // Return if node can be placed and at which point according to mouse position and current action
    getNodeAcceptablePlacement(action, node, point, synoptique) {
        switch(action.name) {
            case 'add_track':
                if(node.data.path.length > 1) {
                    let previousPathStep = node.data.path[node.data.path.length - 2];
                    let normalizedPoint = { x: point.x - previousPathStep.x, y: point.y - previousPathStep.y }
                    let normalizedPointInnerDifference = Math.abs(normalizedPoint.x) - Math.abs(normalizedPoint.y);
                    if(normalizedPoint.x !== 0 && normalizedPoint.y !== 0 && normalizedPointInnerDifference !== 0) 
                        // Le point n'est pas dans un angle de 45°C par rapport au précédent (cela est nécessaire pour que la voie reste sur des points d'accroche)
                    {
                        let minMeasure = Math.min(normalizedPoint.x, normalizedPoint.y, normalizedPointInnerDifference);
                        if(normalizedPointInnerDifference == minMeasure) return {
                                x: previousPathStep.x + (normalizedPointInnerDifference * normalizedPoint.x / Math.abs(normalizedPoint.x)),
                                y: previousPathStep.y + (normalizedPointInnerDifference * normalizedPoint.y / Math.abs(normalizedPoint.y))
                            };
                        else if(normalizedPoint.y == minMeasure) return { x: previousPathStep.x, y: point.y + (normalizedPoint.x % 2) };
                        else if(normalizedPoint.x == minMeasure) return { x: point.x + (normalizedPoint.y % 2), y: previousPathStep.y };
                    }
                }
                return point;
            case 'add_switch':
                if(node.data.branchingTracks !== undefined && node.data.branchingTracks !== null) {
                    if(arePointsIdentical(node.data.intersection, point)) return null;
                    else {
                        let closestPointOnNewTrack = getClosestTrackPoint(point, node.data.availableTracks, { maximumDistance: 5 })?.point;
                        return node.data.branchingTracks.map(bt => bt.position)
                            .reduce(
                                (pPrevious, pCurrent) => arePointsIdentical(pCurrent, point) ? pCurrent : pPrevious,
                                closestPointOnNewTrack
                            );
                    }
                } else return getClosestTracksIntersection(point, synoptique.tracks, { maximumDistance: 5 });
            case 'instrumentalize_track':
                var tracks = node.data.positionEnd == null ? synoptique.tracks : [node.track];
                return getClosestTrackPoint(point, tracks, { maximumDistance: 5 })?.point;
            case 'set_track_under_construction':
                var tracks = node.data.positionEnd == null ? synoptique.tracks : [node.track];
                return getClosestTrackPoint(point, tracks, { maximumDistance: 5 }, true)?.point;
            case 'add_text':
            case 'add_tip':
                return point;
            case 'add_edge':
                // Get closest empty edge of any track
                var nearestTrack = synoptique.tracks.reduce((tPrevious, tCurrent) => {
                    let distanceFromStart = computeDistanceBetweenPoints(tCurrent.data.path[0], point);
                    let distanceFromEnd = computeDistanceBetweenPoints(tCurrent.data.path[tCurrent.data.path.length - 1], point);
                    if(distanceFromStart < distanceFromEnd) {
                        var startingPoint = tCurrent.data.path[0];
                        startingPoint.track = tCurrent;
                        startingPoint.segmentAngle = computeVectorAngle(tCurrent.data.path[0], tCurrent.data.path[1]);
                        if(tCurrent.startingEdge == null && distanceFromStart < tPrevious.distance) return { point: startingPoint, distance: distanceFromStart };
                    } else if(tCurrent.closingEdge == null && distanceFromEnd < tPrevious.distance) {
                        var closingPoint = tCurrent.data.path[tCurrent.data.path.length - 1];
                        closingPoint.track = tCurrent;
                        closingPoint.segmentAngle = computeVectorAngle(tCurrent.data.path[tCurrent.data.path.length - 1], tCurrent.data.path[tCurrent.data.path.length - 2]);
                        return { point: closingPoint, distance: distanceFromEnd };
                    }
                    return tPrevious;
                }, { distance: 5 });
                return nearestTrack?.point;
            case 'add_derailer':
                return getClosestTrackPoint(point, synoptique.tracks, { maximumDistance: 5 })?.point;
        }
    }
}
