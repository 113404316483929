import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    elementToShow;

    static values = {
        elementToHideSelector: String,
    }

    connect() {
        if($('.submit-message-container').length == 0)
            $('body').prepend(
                `<div class="submit-message-container"><div class="loader"></div></div>`
            ); 
        this.elementToShow = $('.submit-message-container');
        this.elementToShow.hide();
        
        $(this.element).on('click', () => { 
            $(document).css('cursor', 'wait');
            if(this.elementToHideSelectorValue) $(this.elementToHideSelectorValue).hide();
            this.elementToShow.show();
            this.elementToShow.css('display', 'flex');
        })
    }
}