const defaultConfig = {
    step: 10,
    color: '#888',
    lineWidth: 1,
    transformationMatrix: [
        [1, 0, 0],
        [0, 1, 0],
        [0, 0, 1]
    ]
}

export class InfiniteGrid {
    offset = { x: 0, y: 0};
    canvas;
    display = true;
    start;
    step;
    lineWidth;
    strokeStyle;
    transformation;

    _ctx;
    _pointsSpacing;
    _lastMousePosition;

    constructor(canvas, { step, lineWidth, color, transformationMatrix } = {}) {
        this.canvas = canvas;
        this.step = step ?? defaultConfig.step;
        this.lineWidth = lineWidth ?? defaultConfig.lineWidth;
        this.strokeStyle = color ?? defaultConfig.color;
        var transformationMatrix = transformationMatrix ?? defaultConfig.transformationMatrix;
        this.transformation = {
            a: transformationMatrix[0][0],
            b: transformationMatrix[1][0],
            c: transformationMatrix[0][1],
            d: transformationMatrix[1][1],
            e: transformationMatrix[0][2],
            f: transformationMatrix[1][2]
        }
        this._pointsSpacing = Math.sqrt(2 * Math.pow(this.step, 2)) / 2 / 0.707;
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
    }

    init(show = true) {
        this._ctx = this.canvas.getContext('2d');
        this._ctx.setTransform(this.transformation);
        this._ctx.lineWidth = this.lineWidth;
        this._ctx.strokeStyle = this.strokeStyle;

        this.canvas.addEventListener("mousedown", e => {
            this.reset();
            this.start = this.getPos(e)
        });

        this.canvas.addEventListener("mouseup", () => this.reset());
        this.canvas.addEventListener("mouseleave", () => this.reset());

        this.canvas.addEventListener("mousemove", e => {
            let pos = this.getPos(e);
            this._lastMousePosition = { x: pos.x - this.canvas.getBoundingClientRect().left, y: pos.y - this.canvas.getBoundingClientRect().top };
            // Only move the grid when we registered a mousedown event
            if (!this.start) {
                if(!this.display) return;
                $(this.canvas).trigger('mouseMove', this.getMouseAnchoringPoint());
                return;
            };
            // Move coordinate system in the same way as the cursor
            let translationOffset = { x: pos.x - this.start.x, y: pos.y - this.start.y };
            let adjustedTranslationOffset = InfiniteGrid.rotatePoint(translationOffset.x * 0.707, translationOffset.y * 0.707, 45);
            this._ctx.translate(adjustedTranslationOffset.x, adjustedTranslationOffset.y);
            this.draw();
            this.start = pos;
            // Garde trace du déplacement de la grille
            this.offset.x += translationOffset.x;
            this.offset.y += translationOffset.y;
            $(this.canvas).trigger('gridMove', this.offset, translationOffset);
        });

        this.display = show;
        this.draw();
    }

    hide() {
        $(this.canvas).css('opacity', '0');
        this.display = false;
    }
    
    show() {
        $(this.canvas).css('opacity', '1');
        this.display = true;
        this.reset();
    }

    draw() {
        if(!this.display) return;
        let left = 0.5 - Math.ceil(this.canvas.width / this.step) * this.step;
        let top = 0.5 - Math.ceil(this.canvas.height / this.step) * this.step;
        let right = 2 * this.canvas.width;
        let bottom = 2 * this.canvas.height;
        this._ctx.clearRect(left, top, right - left, bottom - top);
        this._ctx.beginPath();
        for (let x = left; x < right; x += this.step) {
            this._ctx.moveTo(x, top);
            this._ctx.lineTo(x, bottom);
        }
        for (let y = top; y < bottom; y += this.step) {
            this._ctx.moveTo(left, y);
            this._ctx.lineTo(right, y);
        }
        this._ctx.stroke();
    }

    placeElement(element, { x: iX, y: iY})
    {
        $(element).css('left', iX * this._pointsSpacing);
        $(element).css('top', iY * this._pointsSpacing);
    }

    connectElements(connectorSVG, points, options = {}) {
        let mins = { x: Math.min(...points.map((p) => p.x)), y: Math.min(...points.map((p) => p.y)) }
        let maxes = { x: Math.max(...points.map((p) => p.x)), y: Math.max(...points.map((p) => p.y)) }
        let width = maxes.x - mins.x;
        let height = maxes.y - mins.y;
        if(options.placeSVG !== false) {
            $(connectorSVG).css('left', mins.x * this._pointsSpacing);
            $(connectorSVG).css('top', mins.y * this._pointsSpacing);
            $(connectorSVG).css('width', width * this._pointsSpacing);
            $(connectorSVG).css('height', height * this._pointsSpacing);
        }
        let lineTag = options.elementId ? $(connectorSVG).find('#' + options.elementId) : $(connectorSVG).find('polyline').last();
        lineTag.attr('points', points.map((p) => (p.x - mins.x) + ',' + (p.y - mins.y)).join(' '));
        if(options.placeSVG !== false) {
            $(connectorSVG).attr('viewBox', '0 0 ' + Math.max(width, 1) + ' ' + Math.max(height, 1));
        }
    }

    drawHatches(connectorSVG, points, options = {}) {
        for (let i = 0; i < points.length - 1; i++) {
            // let mins = { x: Math.min(...points.map((p) => p.x)), y: Math.min(...points.map((p) => p.y)) }
            const start = points[i];
            const end = points[i + 1];

            // Calculate the angle of the current segment
            const dx = end.x - start.x;
            const dy = end.y - start.y;
            const segmentLength = Math.sqrt(dx * dx + dy * dy);
            const angle = Math.atan2(dy, dx);

            // Calculate unit vector perpendicular to the segment
            const offsetX = (dy / segmentLength) * 6;
            const offsetY = -(dx / segmentLength) * 6;

            // Draw hatch lines along the segment at intervals
            for (let d = 0; d < segmentLength; d += options.hatchSpacing) {
                const x = start.x - Math.min(start.x, end.x) + (dx * d) / segmentLength;
                const y = start.y - Math.min(start.y, end.y) + (dy * d) / segmentLength;

                // Start and end points for each hatch line
                const x1 = x + offsetX - (options.hatchLength / 2) * Math.cos(angle + Math.PI / 4);
                const y1 = y + offsetY - (options.hatchLength / 2) * Math.sin(angle + Math.PI / 4);
                const x2 = x + offsetX + (options.hatchLength / 2) * Math.cos(angle + Math.PI / 4);
                const y2 = y + offsetY + (options.hatchLength / 2) * Math.sin(angle + Math.PI / 4);

                // Create the hatch line and append to the SVG
                const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
                line.setAttribute("x1", x1);
                line.setAttribute("y1", y1);
                line.setAttribute("x2", x2);
                line.setAttribute("y2", y2);
                line.setAttribute("stroke", "red");
                line.setAttribute("stroke-width", "1");
                connectorSVG.append(line);
            }
        }
    }

    getPos = (e) => ({
        x: e.clientX - this.canvas.offsetLeft,
        y: e.clientY - this.canvas.offsetTop 
    });

    reset() {
        this.start = null;
        this._ctx.setTransform(this.transformation); // reset translation
        this.draw();
        this.offset.x = Math.round((this.offset.x) / (this._pointsSpacing * 2)) * this._pointsSpacing * 2;
        this.offset.y = Math.round((this.offset.y) / (this._pointsSpacing * 2)) * this._pointsSpacing * 2;
        $(this.canvas).trigger('gridMove', this.offset);
    }

    getMouseAnchoringPoint() {
        let colIndexOffset =  Math.round(this.offset.x / this._pointsSpacing);
        let rowIndexOffset =  Math.round(this.offset.y / this._pointsSpacing);
        let colIndex = Math.round(this._lastMousePosition.x / this._pointsSpacing);
        let rowIndex = Math.round(this._lastMousePosition.y / this._pointsSpacing);

        // Check if both indexes are odd or even, if not, the anchor point is somewhere else
        if(colIndex % 2 != rowIndex % 2) {
            let surroundingPoints = [
                { x: (colIndex - 1) * this._pointsSpacing, y: rowIndex * this._pointsSpacing, iX : colIndex - 1, iY: rowIndex },
                { x: colIndex * this._pointsSpacing, y: (rowIndex - 1) * this._pointsSpacing, iX : colIndex, iY: rowIndex - 1 },
                { x: (colIndex + 1) * this._pointsSpacing, y: rowIndex * this._pointsSpacing, iX : colIndex + 1, iY: rowIndex },
                { x: colIndex * this._pointsSpacing, y: (rowIndex + 1) * this._pointsSpacing, iX : colIndex, iY: rowIndex + 1 },
            ]
            let nearestPointIndex = surroundingPoints
                .map((p) => InfiniteGrid.computeDistanceBetweenPoints(p, { x: this._lastMousePosition.x, y: this._lastMousePosition.y  }))
                .reduce((kv, distance, i) => distance < kv.v ? { k: i, v: distance } : kv, { k: 0, v: this._pointsSpacing})
                .k;
            return { x: surroundingPoints[nearestPointIndex].iX - colIndexOffset, y: surroundingPoints[nearestPointIndex].iY - rowIndexOffset };
        } else {
            return { x: colIndex - colIndexOffset, y: rowIndex - rowIndexOffset };
        }
    }

    static computeDistanceBetweenPoints(A, B) {
        return Math.sqrt(Math.pow(A.x - B.x, 2) + Math.pow(A.y - B.y, 2));
    }

    static rotatePoint(x, y, angle) {
        var angleRad = angle * Math.PI / 180;
        return {
            x: x * Math.cos(angleRad) - y * Math.sin(angleRad),
            y: x * Math.sin(angleRad) + y * Math.cos(angleRad)
        };
    }

    static computeAngleABC(vA, vC, vB = { x: 0, y: 0 }) {
        vA = { x: vA.x - vB.x, y: vA.y - vB.y };
        vC = { x: vC.x - vB.x, y: vC.y - vB.y };
        const dotProduct = vA.x * vC.x + vA.y * vC.y;
        const lengthAB = Math.sqrt(vA.x * vA.x + vA.y * vA.y);
        const lengthBC = Math.sqrt(vC.x * vC.x + vC.y * vC.y);
        const angleRadians = Math.acos(dotProduct / (lengthAB * lengthBC));
        var angle = (angleRadians * 180) / Math.PI;
        if(angle === NaN) return 90
        return vC.x >= 0 ? angle : -angle;
    }
}